<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/详情
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div>
      <ykc-detail :titles="['活动信息']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="90px">
              <ykc-detail-item label="活动名称：">
                <span>{{ dealData(detail.activityName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动类型：">
                <span>扫码领券</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动状态：">
                <span>
                  {{ statusLabel(detail.activityStatus) }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="活动时间：">
                <span>
                  {{ dealData(detail.activityBeginTime) }} ~{{ dealData(detail.activityEndTime) }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="活动标题：">
                <span>{{ dealData(detail.activityTitle) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动图片：" v-if="detail.activityImage">
                <div class="activity-photo">
                  <viewer :images="[detail.activityImage || defaultImageUrl]">
                    <img
                      :src="detail.activityImage || defaultImageUrl"
                      alt=""
                      width="80"
                      height="80" />
                  </viewer>
                </div>
              </ykc-detail-item>
              <ykc-detail-item label="活动规则：">
                <span>{{ dealData(detail.activityRule) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动二维码：" v-if="detail.qrcode">
                <div class="activity-photo">
                  <viewer :images="[detail.qrcode || defaultImageUrl]">
                    <img :src="detail.qrcode || defaultImageUrl" alt="" width="80" height="80" />
                  </viewer>
                </div>
              </ykc-detail-item>
              <ykc-detail-item label="修改时间：">
                <span>{{ dealData(detail.modifiedTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="修改人：">
                <span>{{ dealData(detail.createdAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建时间：">
                <span>{{ dealData(detail.createdTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建人：">
                <span>{{ dealData(detail.createdAccount) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail :titles="['活动配置']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="100px">
              <ykc-detail-item label="卡券设置：">
                <ykc-table :data="detail.rewardList" :columns="tableColumns"></ykc-table>
              </ykc-detail-item>
              <ykc-detail-item label="发放规则：">
                <span>充电满{{ dealData(detail.limitPower) }}度赠送</span>
              </ykc-detail-item>

              <ykc-detail-item label="限领次数：">
                <span>
                  {{ dealData(detail.limitCount) }}次/人/{{
                    detail.limitType === '1' ? '天' : '周期'
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="用户范围：">
                <span>{{ userRangeLabel(detail.userScope) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <div class="onlineConent" style="margin-top: 12px" v-if="detail.userScope !== '3'">
        <ykc-detail :isHead="false" :tips="false" :className="'all'">
          <div slot="cvBody">
            <ykc-table
              ref="YkcTable"
              :data="userTableData"
              :columns="userTableColumns"
              title="活动用户列表">
              <template #headerButtons>
                <ykc-button
                  type="plain"
                  @click="doExport"
                  v-rbac="'marketing:platform:card:download'">
                  导出
                </ykc-button>
              </template>
              <ykc-pagination
                :total="userTabTotal"
                slot="pagination"
                :pageSize.sync="userTabPageSize"
                :currentPage="userTabCurrentPage"
                @size-change="handleUserTabSizeChange"
                @current-change="handleUserTabCurrentChange" />
            </ykc-table>
          </div>
        </ykc-detail>
      </div>
    </div>
  </scroll-layout>
</template>

<script>
  import { couponActivity } from '@/service/apis';
  import { offlineExport } from '@/utils';

  export default {
    props: {
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        defaultImageUrl: '',
        detail: {}, // 详情数据
        // 表格列
        tableColumns: [
          {
            label: '卡券名称',
            prop: 'rewardName',
            width: 240,
          },
          { label: '发送数量(张/人)', prop: 'rewardCount', width: 240 },
        ],
        stationTableData: [], // 电站列表数据
        stationTableColumns: [],
        userTableData: [],
        userTableColumns: [],
        activeName: 'first',
        stationTabTotal: 10,
        stationTabPageSize: 10,
        stationTabCurrentPage: 1,
        userTabTotal: 10,
        userTabPageSize: 10,
        userTabCurrentPage: 1,
      };
    },
    computed: {},
    created() {
      this.getDetail();
    },
    methods: {
      doExport() {
        offlineExport({
          downloadType: 'marketing_export',
          jsonNode: {
            downloadKey: 'scanActivityUserList',
            activityId: this.activityId,
          },
        });
      },
      dicStatus() {
        // 活动状态（1:进行中，2:未开始，3:已结束(手动停用)，4:已结束(活动到期)，5:已结束(卡券不足)，6:已结束(卡券失效)）
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已结束(手动停用)' },
          { id: '4', name: '已结束(活动到期)' },
          { id: '5', name: '已结束(卡券不足)' },
          { id: '6', name: '已结束(卡券失效)' },
        ];
      },
      /**
       * 卡券活动字典对应的文案
       * @param status
       * @returns {string|*|string}
       */
      statusLabel(status) {
        if (!status) {
          return '——';
        }
        const obj = this.dicStatus()?.find(item => item.id === status);
        return obj?.name || '——';
      },
      getDetail() {
        // 有问题 详情缺少remark字段
        const requestDetail = couponActivity.scanActivityDetail({
          activityId: this.activityId,
        });
        requestDetail.then(res => {
          this.detail = res || {};
          this.fixTabsHeaders();
          this.getUsers();
        });
      },
      fixTabsHeaders() {
        if (this.detail.userScope === '1') {
          this.userTableColumns = [
            { label: '客户编码', prop: 'dataId', minWidth: '150px' },
            { label: '客户名称', prop: 'dataName', minWidth: '150px' },
            { label: '客户类型', prop: 'orgTypeText', minWidth: '150px' },
          ];
        } else if (this.detail.userScope === '2') {
          this.userTableColumns = [
            { label: '用户分组', prop: 'dataName', minWidth: '150px' },
            { label: '用户数量', prop: 'userCount', minWidth: '150px' },
          ];
        }
      },
      userRangeLabel(scope) {
        if (scope === '1') {
          return '按客户';
        }
        if (scope === '2') {
          return '按用户分组 ';
        }
        if (scope === '3') {
          return '全部用户';
        }
        return '——';
      },
      /**
       * 获取用户信息
       */
      getUsers() {
        const usersRequest = couponActivity.scanActivityUsers({
          activityId: this.activityId,
          current: this.userTabCurrentPage,
          size: this.userTabPageSize,
        }); // 详情用户列表
        usersRequest.then(res => {
          this.userTableData = res?.records || [];
          this.userTabTotal = res?.total;
        });
      },
      handleUserTabSizeChange(size) {
        console.log('使用了sync修饰符', this.pageSize);
        this.userTabPageSize = size;
        this.getUsers();
      },
      handleUserTabCurrentChange(currentPage) {
        console.log('没有使用了sync修饰符', currentPage);
        this.userTabCurrentPage = currentPage;
        this.getUsers();
      },
      /**
       * 获取详情数据
       */
      dealData(data) {
        return data || '—';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .all {
    margin-bottom: 16px;
  }
  .ykc-tree.detailTree {
    padding-top: 0;
  }
  .activity-photo {
    width: 80px;
    height: 80px;
    background: #f5f8ff;
    border-radius: 10px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 10px;
    }
  }
</style>
