<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/详情
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div>
      <ykc-detail :titles="['活动信息']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="90px">
              <ykc-detail-item label="活动名称：">
                <span>{{ dealData(detail.activityName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动类型：">
                <span>注册活动</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动时间：">
                <span>
                  {{ dealData(detail.activityBeginTime) }} ~{{ dealData(detail.activityEndTime) }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="活动状态：">
                <span>{{ statusLabel(detail.activityStatus) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="修改时间：">
                <span>{{ dealData(detail.modifiedTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="修改人：">
                <span>{{ dealData(detail.modifiedAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建时间：">
                <span>{{ dealData(detail.createdTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建人：">
                <span>{{ dealData(detail.createdAccount) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail :titles="['活动配置']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="100px">
              <ykc-detail-item label="卡券设置：">
                <ykc-table :data="detail.rewardList" :columns="tableColumns"></ykc-table>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
    </div>
  </scroll-layout>
</template>

<script>
  import { couponActivity } from '@/service/apis';

  export default {
    props: {
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        name: 'RegisterDetail',
        detail: {},
        tableColumns: [
          {
            label: '卡券名称',
            prop: 'rewardName',
            width: 240,
          },
          { label: '发送数量(张/人)', prop: 'rewardCount', width: 240 },
        ],
      };
    },
    computed: {},
    created() {
      this.getDetail();
    },
    methods: {
      dicStatus() {
        // 活动状态（1:进行中，2:未开始，3:已结束(手动停用)，4:已结束(活动到期)，5:已结束(卡券不足)，6:已结束(卡券失效)）
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已结束(手动停用)' },
          { id: '4', name: '已结束(活动到期)' },
          { id: '5', name: '已结束(卡券不足)' },
          { id: '6', name: '已结束(卡券失效)' },
        ];
      },
      statusLabel(status) {
        if (!status) {
          return '——';
        }
        const obj = this.dicStatus()?.find(item => item.id === status);
        return obj?.name || '——';
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        couponActivity.registerActivityDetail({ activityId: this.activityId }).then(res => {
          if (res) {
            this.detail = res || {};
          }
        });
      },
      dealData(data) {
        return data || '—';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .all {
    margin-bottom: 16px;
  }
  .ykc-tree.detailTree {
    padding-top: 0;
  }
</style>
