<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/详情
-->
<template>
  <register-detail v-if="type === '1'" :activityId="id" />
  <recharge-detail v-else-if="type === '2'" :activityId="id" />
  <push-detail v-else-if="type === '3'" :activityId="id" />
  <charge-detail v-else-if="type === '4'" :activityId="id" />
  <scan-detail v-else-if="type === '5'" :activityId="id" />
  <exchange-detail v-else-if="type === '6'" :activityId="id" />
</template>

<script>
  import RegisterDetail from './details/RegisterDetail';
  import RechargeDetail from './details/RechargeDetail';
  import PushDetail from './details/PushDetail';
  import ChargeDetail from './details/ChargeDetail';
  import ScanDetail from './details/ScanDetail';
  import ExchangeDetail from './details/ExchangeDetail';

  export default {
    components: {
      ExchangeDetail,
      ScanDetail,
      ChargeDetail,
      PushDetail,
      RechargeDetail,
      RegisterDetail,
    },
    data() {
      return {
        detail: {},
        id: '',
        type: '', // 类型
      };
    },
    computed: {},
    created() {
      const { activityId, activityType } = this.$route.query;
      this.id = activityId || '';
      // 活动类型(1:注册领取，2:充值领取，3:定向发放，4:充电领取，5:扫码领取，6:券码兑换)
      this.type = activityType || '1';
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .all {
    margin-bottom: 16px;
  }
  .ykc-tree.detailTree {
    padding-top: 0;
  }
</style>
